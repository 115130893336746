html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-sans);
  font-size: 1.125rem;
  color: var(--grayishblue);
  line-height: 1.3;
}
body.noscroll {
  overflow: hidden;
}

.flex {
  display: flex;
}
.flex-jc-sb {
  justify-content: space-between;
}
.flex-jc-c {
  justify-content: center;
}
.flex-ai-c {
  align-items: center;
}

button,
.button {
  padding: 0.875rem 2.1875rem;
  border-radius: 3.125rem;
  border: 0;
  color: var(--white);
  cursor: pointer;
  background: linear-gradient(to right, var(--limegreen), var(--brightcyan));
}

@media (max-width: 63.9375em) {
  .hide-for-mobile {
    display: none;
  }
}

@media (min-width: 64em) {
  .hide-for-desktop {
    display: none;
  }
}

:root {
  --darkblue: hsl(233, 26%, 24%);
  --limegreen: hsl(136, 65%, 51%);
  --brightcyan: hsl(192, 70%, 51%);
  --grayishblue: hsl(233, 8%, 62%);
  --lightgrayish-blue: hsl(220, 16%, 96%);
  --verylight-gray: hsl(0, 0%, 98%);
  --white: hsl(0, 0%, 100%);
  --button-background: linear-gradient(
    225deg,
    var(--limegreen) 0%,
    var(--brightcyan) 98.02%
  );
}

:root {
  --font-sans: "Public Sans", sans-serif;
}

h1,
h2,
h3 {
  margin-top: 0;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

.has-fade {
  visibility: hidden;
}

@keyframes fade-in {
  from {
    visibility: hidden;
    opacity: 0;
  }
  1% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: visible;
    opacity: 1;
  }
}
.fade-in {
  animation: fade-in 300ms ease-in-out forwards;
}

@keyframes fade-out {
  from {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
}
.fade-out {
  animation: fade-out 300ms ease-in-out forwards;
}

@media (max-width: 39.9375em) {
  .header {
    min-height: 3.625rem;
  }
}
.header.open .header__toggle > span:first-child {
  transform: rotate(45deg);
}
.header.open .header__toggle > span:nth-child(2) {
  opacity: 0;
}
.header.open .header__toggle > span:last-child {
  transform: rotate(-45deg);
}
.header .overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-image: linear-gradient(var(--darkblue), transparent);
}
.header .container {
  padding-left: 10.1875rem;
  padding-right: 10.1875rem;
}
@media (max-width: 39.9375em) {
  .header .container {
    padding-left: 1.375rem;
    padding-right: 1.375rem;
  }
}
.header nav {
  position: relative;
  background-color: var(--white);
  padding-top: 1.0625rem;
  padding-bottom: 1.0625rem;
}
.header__logo img {
  width: 8.8125rem;
  height: 1.375rem;
}
.header__toggle > span {
  display: block;
  width: 1.625rem;
  height: 0.125rem;
  background-color: var(--darkblue);
  transition: all 300ms ease-in-out;
  transform-origin: 0.1875rem 0.0625rem;
}
.header__toggle > span:not(:last-child) {
  margin-bottom: 0.3125rem;
}
.header__menu {
  position: absolute;
  width: calc(100% - 3rem);
  left: 50%;
  transform: translateX(-50%);
  background: var(--white);
  padding: 1.625rem;
  margin-top: 1.5rem;
  border-radius: 0.3125rem;
}
.header__menu a {
  color: var(--darkblue);
  padding: 0.625rem;
  display: block;
  text-align: center;
}
.header__links a {
  position: relative;
  font-size: 0.875rem;
  color: var(--grayishblue);
  transition: color 500ms ease-in-out;
}
.header__links a:not(:last-child) {
  margin-right: 2rem;
}
.header__links a::before {
  background: var(--button-background);
  bottom: -1.875rem;
  content: "";
  display: block;
  height: 0.3125rem;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  transition: opacity 500ms ease-in-out;
}
.header__links a:hover {
  color: var(--darkblue);
}
.header__links a:hover::before {
  opacity: 1;
}
.header__cta {
  font-size: 0.875rem;
  color: var(--white);
  transition: opacity 500ms ease-in-out;
}
.header__cta:hover {
  opacity: 0.75;
}

.section1 {
  display: flex;
  flex-direction: row;
  height: 40.75rem;
  padding-left: 10.1875rem;
  background-color: var(--white);
}
.section1__div1 {
  margin-top: 18.75rem;
}
@media (max-width: 39.9375em) {
  .section1__div1 {
    position: absolute;
    left: 1.875rem;
    margin-right: 0.9375rem;
    text-align: center;
    margin-top: 17.8125rem;
  }
  .section1__div1 p br {
    display: none;
  }
}
.section1__header {
  font-weight: 400;
  font-size: 2.5rem;
  color: var(--darkblue);
}
.section1__paragraph {
  font-size: 0.9375rem;
  margin-bottom: 2.375rem;
}
.section1__div2 {
  width: 46.5625rem;
  background: url(/images/bg-intro-desktop.svg);
  background-position: 0% 68%;
  margin-left: 5.625rem;
}
@media (max-width: 39.9375em) {
  .section1__div2 {
    display: none;
  }
}
.section1__img {
  background: url(/images/image-mockups.png);
  height: 48.625rem;
  width: 38.5625rem;
  margin-left: 9.375rem;
  background-position: -12% 80%;
}

.section2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height: 42rem;
  background-color: var(--lightgrayish-blue);
  padding-left: 9.375rem;
  padding-right: 7.75rem;
  padding-top: 6.6875rem;
  padding-bottom: 6.6875rem;
}
@media (max-width: 75rem) {
  .section2 {
    min-width: 75rem;
  }
}
@media (max-width: 34.8125rem) {
  .section2 {
    min-width: 34.8125rem;
  }
}
@media (max-width: 39.9375em) {
  .section2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 87.8125rem;
    justify-items: center;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    text-align: center;
    min-width: 100%;
  }
}
.section2 img {
  margin-bottom: 2.5625rem;
}
@media (max-width: 39.9375em) {
  .section2 img {
    justify-self: center;
  }
}
.section2 h3 {
  font-weight: 200;
  color: var(--darkblue);
  margin-bottom: 2.1875rem;
}
.section2 p {
  font-size: 0.8125rem;
  text-align: justify;
}
@media (max-width: 39.9375em) {
  .section2 p br {
    display: none;
  }
}
.section2__desc {
  grid-column: 1/5;
  grid-row: 1/1.5;
}
.section2__desc p {
  font-size: 0.875rem;
}
.section2__desc h2 {
  font-weight: 300;
  color: var(--darkblue);
}

.section3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1.5625rem;
  height: 41.1875rem;
  background-color: var(--white);
  padding-top: 5.8125rem;
  padding-left: 10.125rem;
  padding-right: 12.5rem;
  padding-bottom: 6.4375rem;
}
@media (max-width: 39.9375em) {
  .section3 {
    display: flex;
    flex-direction: column;
    height: 115.25rem;
    justify-items: center;
    align-items: center;
    padding-left: 6.875rem;
    padding-right: 6.875rem;
  }
}
.section3 h2 {
  font-weight: 300;
  color: var(--darkblue);
}
@media (max-width: 39.9375em) {
  .section3 h2 {
    text-align: center;
  }
}
.section3 h5 {
  font-size: 0.625rem;
  font-weight: 100;
  margin-top: 1.5625rem;
}
.section3 h4 {
  font-weight: 100;
  color: var(--darkblue);
  margin-top: 1.125rem;
}
.section3 p {
  font-size: 0.8125rem;
  margin-top: 0.75rem;
  text-align: justify;
}
@media (max-width: 39.9375em) {
  .section3 p br {
    display: none;
  }
}
.section3 img {
  width: 16rem;
  height: 12.4375rem;
  border-radius: 2%;
}
@media (max-width: 39.9375em) {
  .section3 img {
    min-width: 90vw;
  }
}
.section3__desc {
  grid-column: 1/5;
  grid-row: 1/1.5;
}

.footer {
  display: grid;
  padding-top: 2.875rem;
  padding-left: 8.25rem;
  padding-right: 9.875rem;
  padding-bottom: 2.5rem;
  grid-template-columns: 1fr 1fr 1fr 3fr;
  column-gap: 7.1875rem;
  height: 12rem;
  background-color: var(--darkblue);
}
@media (max-width: 75rem) {
  .footer {
    min-width: 75rem;
  }
}
@media (max-width: 34.8125rem) {
  .footer {
    min-width: 34.8125rem;
  }
}
@media (max-width: 39.9375em) {
  .footer {
    display: flex;
    flex-direction: column;
    height: 43.75rem;
    justify-items: center;
    align-items: center;
    text-align: center;
    min-width: 100%;
  }
}
.footer p {
  margin-top: 0.9375rem;
  font-size: 0.875rem;
}
.footer img {
  margin-bottom: 3.125rem;
}
.footer__sub img {
  margin-right: 0.625rem;
}
@media (max-width: 39.9375em) {
  .footer__last {
    display: flex;
    flex-direction: column;
  }
}
.footer__last a {
  float: right;
  max-height: 2.5rem;
  min-width: 10.3125rem;
}
.footer__last p {
  margin-top: 1.6875rem;
  float: right;
}

.wrapper {
  max-width: 90rem;
}
@media (max-width: 39.9375em) {
  .wrapper {
    max-width: 100%;
  }
}