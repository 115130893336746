@use "../utils/" as *;

.header {
  @include breakpoint-down(small){
    min-height: rem(58);
  }
  //hamburger open styles
  &.open {
    .header__toggle {
      > span:first-child {
        transform: rotate(45deg);
      }
      > span:nth-child(2) {
        opacity: 0;
      }
      > span:last-child {
        transform: rotate(-45deg);
      }
    }
  }
  .overlay {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: linear-gradient(var(--darkblue), transparent);
  }
  //spacing
  .container {
    padding-left: rem(163);
    padding-right: rem(163);
  
    @include breakpoint-down(small) {
      padding-left: rem(22);
      padding-right: rem(22);
     
    }
  }
  nav {
    position: relative;
    background-color: var(--white);
    padding-top: rem(17);
    padding-bottom: rem(17);

  }
  &__logo {
    img {
      width: rem(141);
      height: rem(22);
    }
  }
  &__toggle {
    > span {
      display: block;
      width: rem(26);
      height: rem(2);
      background-color: var(--darkblue);
      transition: all 300ms ease-in-out;
      transform-origin: rem(3) rem(1);

      &:not(:last-child) {
        margin-bottom: rem(5);
      }
    }
  }
  &__menu {
    position: absolute;
    width: calc(100% - rem(48));
    left: 50%;
    transform: translateX(-50%);
    background: var(--white);
    padding: rem(26);
    margin-top: rem(24);
    border-radius: rem(5);
    a {
      color: var(--darkblue);
      padding: rem(10);
      display: block;
      text-align: center;
    }
  }
  &__links {
    a {
      position: relative;
      font-size: rem(14);
      color: var(--grayishblue);
      transition: color 500ms ease-in-out;
      &:not(:last-child) {
        margin-right: rem(32);
      }
      &::before {
        background: var(--button-background);
        bottom: rem(-30);
        content: "";
        display: block;
        height: rem(5);
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        transition: opacity 500ms ease-in-out;
      }
      &:hover {
        color: var(--darkblue);
        &::before {
          opacity: 1;
        }
      }
    }
  }

  &__cta {
    font-size: rem(14);
    color: var(--white);
    transition: opacity 500ms ease-in-out;

    &:hover {
      opacity: 0.75;
    }
  }
}
