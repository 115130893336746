@use "../utils/" as *;

.section3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: rem(25);
   // min-width: rem(1110);
  height: rem(659);
  background-color: var(--white);
  padding-top: rem(93);
  padding-left: rem(162);
  padding-right: rem(200);
  padding-bottom: rem(103);

  
  

  
  @include breakpoint-down(small) {
    display: flex;
    flex-direction: column;
    height: rem(1844);
    justify-items: center;
    align-items: center;
    padding-left: rem(110);
    padding-right: rem(110);
    //min-width: rem(639);

    
  }
  h2 {
    font-weight: 300;
    color: var(--darkblue);
    @include breakpoint-down(small){
      text-align: center;
    }
  }
  h5 {
    font-size: rem(10);
    font-weight: 100;
    margin-top: rem(25);
  }
  h4 {
    font-weight: 100;
    color: var(--darkblue);
    margin-top: rem(18);
  }
  p {
    font-size: rem(13);
    margin-top: rem(12);
    text-align: justify;
    @include breakpoint-down(small) {
      br {
        display: none;
      }
    }
  }
  img {
    width: rem(256);
    height: rem(199);
    border-radius: 2%;

    @include breakpoint-down(small) {
      min-width: 90vw;
    }

  
  }
  &__desc {
    grid-column: 1/5;
    grid-row: 1/1.5;
  }
  
}
