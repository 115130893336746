@use "../utils/" as *;

html {
  //Helps with accesability:
  font-size: 100%;
  //This ensures that if you add padding to an element the total width will include the padding and not be in addition to the padding:
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
  font-family: var(--font-sans);
  font-size: rem(18);
  color: var(--grayishblue);
  line-height: 1.3;

  &.noscroll {
    overflow: hidden;
  }
}

.flex {
  display: flex;
  &-jc-sb {
    justify-content: space-between;
  }
  &-jc-c {
    justify-content: center;
  }
  &-ai-c {
    align-items: center;
  }
}

button,
.button {
  padding: rem(14) rem(35);
  border-radius: rem(50);
  border: 0;
  color: var(--white);
  cursor: pointer;
  background: linear-gradient(to right, var(--limegreen), var(--brightcyan));
}

.hide-for-mobile {
  @include breakpoint-down(medium) {
    display: none;
  }
}

.hide-for-desktop {
  @include breakpoint-up(large) {
    display: none;
  }
}
