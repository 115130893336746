@use "../utils/" as *;

.section2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
 //min-width: rem(1110);
  // min-width: rem(1110);
  height: rem(672);
  background-color: var(--lightgrayish-blue);
  padding-left: rem(150);
  padding-right: rem(124);
  padding-top: rem(107);
  padding-bottom: rem(107);

  @media (max-width: rem(1200)){
    min-width: rem(1200);
  }
  
  @media (max-width: rem(557)){
    min-width: rem(557);
  }
  
  @include breakpoint-down(small) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: rem(1405);
    justify-items: center;
    //min-width: rem(639);
    padding-left: rem(20);
  padding-right: rem(20);
  text-align: center;
  min-width: 100%;
  }
  img {
    margin-bottom: rem(41);

    @include breakpoint-down(small) {
      justify-self: center;
    }
  }
  h3 {
    font-weight: 200;
    color: var(--darkblue);
    margin-bottom: rem(35);
  }
  p {
    font-size: rem(13);
    text-align: justify;
    @include breakpoint-down(small) {
      br {
        display: none;
      }
    }
  }
  &__desc {
    grid-column: 1/5;
    grid-row: 1/1.5;
    p {
      font-size: rem(14);
    }
    h2 {
      font-weight: 300;
      color: var(--darkblue);
    }
  }
}
