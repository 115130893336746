@use "../utils/" as *;

.footer {
  display: grid;
  padding-top: rem(46);
  padding-left: rem(132);
  padding-right: rem(158);
  padding-bottom: rem(40);
  grid-template-columns: 1fr 1fr 1fr 3fr;
  column-gap: rem(115);
  height: rem(192);
  //width: 100%;
  //min-width: rem(1110);
  background-color: var(--darkblue);
  
  @media (max-width: rem(1200)){
    min-width: rem(1200);
  }
  @media (max-width: rem(557)){
    min-width: rem(557);
  }
  @include breakpoint-down(small) {
    display: flex;
    flex-direction: column;
    height: rem(700);
    //min-width: rem(639);
    justify-items: center;
    align-items: center;
    text-align: center;
    min-width: 100%;
  }
  p {
    margin-top: rem(15);
    font-size: rem(14);
  }

  img {
    margin-bottom: rem(50);
  }

  &__sub {
    img {
      margin-right: rem(10);
    }
  }
  &__last {

    @include breakpoint-down(small){
      display: flex;
      flex-direction: column;
    }
    a {
      float: right;
      max-height: rem(40);
      min-width: rem(165);
     
    }
    p {
      margin-top: rem(27);
      float: right;
    }
  }
}
