@use "../utils/" as *;
.section1 {
  display: flex;
  flex-direction: row;
  height: rem(652);
  //width: 100%;
  //min-width: rem(1440);
  padding-left: rem(163);
  background-color: var(--white);

  

  
  &__div1 {
    margin-top: rem(300);

    @include breakpoint-down(small) {
      position: absolute;
     left: rem(30);
     margin-right: rem(15);
     text-align: center;
     margin-top: rem(285);
      
     p{
       br{
         display: none;
       }
     }
     // margin-top: rem(300);
    }

    
  }

  &__header {
    font-weight: 400;
    font-size: rem(40);
    color: var(--darkblue);
  }
  &__paragraph {
    font-size: rem(15);
    margin-bottom: rem(38);
  }
  &__div2 {
    width: rem(745);
    background: url(/images/bg-intro-desktop.svg);
    background-position: 0% 68%;
    margin-left: rem(90);

    @include breakpoint-down(small) {
      display: none;
    }
  }
  &__img {
    background: url(/images/image-mockups.png);
    height: rem(778);
    width: rem(617);
    margin-left: rem(150);
    background-position: -12% 80%;
  }
}