h1,
h2,
h3 {
  margin-top: 0;
}

//Styles to remove the underline from our anchor links:
a,
a:visited,
a:active {
  text-decoration: none;
}
