:root {
  --darkblue: hsl(233, 26%, 24%);
  --limegreen: hsl(136, 65%, 51%);
  --brightcyan: hsl(192, 70%, 51%);
  --grayishblue: hsl(233, 8%, 62%);
  --lightgrayish-blue: hsl(220, 16%, 96%);
  --verylight-gray: hsl(0, 0%, 98%);
  --white: hsl(0, 0%, 100%);
  --button-background: linear-gradient(
    225deg,
    var(--limegreen) 0%,
    var(--brightcyan) 98.02%
  );
}
